import { IBusinessConfiguration } from '../interfaces/IBusinessConfiguration';
import { BusinessName } from './BusinessName';
import { COMMON_ALWAYS_REQUIRED_MODULES } from './common/COMMON_ALWAYS_REQUIRED_MODULES';
import { COMMON_BOARD_ACTIVE_MODULES, DEFAULT_ACTIVE_MODULE } from './common/COMMON_BOARD_ACTIVE_MODULES';
import { COMMON_BOARD_REQUIRED_MODULES } from './common/COMMON_BOARD_REQUIRED_MODULES';
import { COMMON_WELCOME_REQUIRED_MODULES } from './common/COMMON_WELCOME_REQUIRED_MODULES';

export const BusinessModuleConfiguration: Record<BusinessName, IBusinessConfiguration> = {
    [BusinessName.Education]: {
        alwaysRequiredModules: { ...COMMON_ALWAYS_REQUIRED_MODULES, DefaultEducationSkin: DEFAULT_ACTIVE_MODULE },
        welcomeRequiredModules: {
            ...COMMON_WELCOME_REQUIRED_MODULES,
            EducationWelcome: {
                /* TODO: !! Use here (+ in same situations) true */
            },
        },
        boardRequiredModules: {
            ...COMMON_BOARD_REQUIRED_MODULES,
        },
        boardActiveModules: COMMON_BOARD_ACTIVE_MODULES,
        welcomeTemplates: [
            // TODO: Probably empty board also as a template
            // TODO: Templates as a modules
            // TODO: Template logic as frontend import
            // Note: [🦧] All places in code which are implementing templates
            {
                title: 'grid',
                image: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/welcome/grid@2x.png',
                modulesOff: ['DotsBackgroundPattern'],
                modulesOn: ['GridBackgroundPattern'],
            },
            {
                title: 'lines',
                image: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/welcome/lines@2x.png',
                modulesOff: ['DotsBackgroundPattern'],
                modulesOn: ['LinesHorizontalBackgroundPattern'],
            },
            {
                title: 'hedu',
                image: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/welcome/hedu@2x.png',
                modulesOff: ['ColorAttribute'],
                modulesOn: [
                    '@collboard/hedu-color-attribute',
                    '@collboard/hedu-tray-tool',
                    'DiceTool',
                    'DiceArt',
                    'CurtainArt',
                    'CurtainTool',
                ],
            },
            {
                title: 'montessori',
                image: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/welcome/montessori@2x.png',
                modulesOff: [],
                modulesOn: ['@collboard/montessori-tool', 'DiceTool', 'DiceArt', 'CurtainArt', 'CurtainTool'],
            },
            {
                title: { en: 'Geography', cs: 'Zeměpis' },
                image: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/welcome/map@2x.png',
                // importAsMaterialize: 'https://collboard.com/opym6bdf3qvkczpdla74',
                modulesOff: [],
                modulesOn: ['@collboard/map-tray-tool-czechia-counties-and-districts'],
            },
            {
                title: { en: 'Worksheet', cs: 'Pracovní list' },
                image: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/welcome/worksheet@2x.png',
                modulesOff: [],
                modulesOn: [
                    '@collboard/background-frames/paper-a2',
                    '@collboard/background-frames/paper-a3',
                    '@collboard/background-frames/paper-a4',
                    '@collboard/background-frames/paper-a5',
                ],
            },
        ],
    },
    [BusinessName.Business]: {
        // TODO: Info about business + presentation
        alwaysRequiredModules: { ...COMMON_ALWAYS_REQUIRED_MODULES, DefaultBusinessSkin: DEFAULT_ACTIVE_MODULE },
        welcomeRequiredModules: { ...COMMON_WELCOME_REQUIRED_MODULES, BusinessWelcome: DEFAULT_ACTIVE_MODULE },
        boardRequiredModules: {
            ...COMMON_BOARD_REQUIRED_MODULES,
        },
        boardActiveModules: {
            ...COMMON_BOARD_ACTIVE_MODULES,

            // More tools for business
            '@hejny/freehand-plus-tool': DEFAULT_ACTIVE_MODULE,
        },
        welcomeTemplates: [],
    },
    [BusinessName.Development]: {
        alwaysRequiredModules: {
            ...COMMON_ALWAYS_REQUIRED_MODULES,
            DefaultDevelopmentSkin: DEFAULT_ACTIVE_MODULE,
            DevelopmentColldev: DEFAULT_ACTIVE_MODULE,
        },
        welcomeRequiredModules: {
            ...COMMON_WELCOME_REQUIRED_MODULES,
            DevelopmentWelcome: DEFAULT_ACTIVE_MODULE,
            ThemeSelect: DEFAULT_ACTIVE_MODULE,
        },
        boardRequiredModules: {
            ...COMMON_BOARD_REQUIRED_MODULES,
        },
        boardActiveModules: {
            ...COMMON_BOARD_ACTIVE_MODULES,
            SampleNotifications: DEFAULT_ACTIVE_MODULE,

            // More tools for testing and playing with
            '@collboard/internal/frame-tool': DEFAULT_ACTIVE_MODULE,
            '@collboard/internal/polygon-tool': DEFAULT_ACTIVE_MODULE,
            '@collboard/internal/point-tool': DEFAULT_ACTIVE_MODULE,
            '@hejny/freehand-plus-tool': DEFAULT_ACTIVE_MODULE,
        },
        welcomeTemplates: [],
    },
};

BusinessModuleConfiguration[BusinessName.Development].welcomeTemplates = [
    ...BusinessModuleConfiguration[BusinessName.Education].welcomeTemplates,
    ...BusinessModuleConfiguration[BusinessName.Business].welcomeTemplates,
];

/**
 * TODO: Make Freehand selected even if dot is loaded after
 */
