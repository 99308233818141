import { Registration } from 'destroyable';
import React from 'react';
import { IVector } from 'xyzt';
import { Separator } from '../30-components/menu/Separator';
import { string_color } from '../40-utils/typeAliases';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
import { IFontStyleAttributeValue } from '../70-attributes/FontStyleAttributeModule';
import { TextArt } from '../71-arts/50-TextArt/TextArt';
import { createTextOperation } from '../71-arts/50-TextArt/textOperations';
internalModules.declareModule(() =>
    makeIconModuleOnModule({
        manifest: {
            name: '@collboard/internal/text-tool',
            deprecatedNames: ['@collboard/text-tool', 'TextTool'],
            title: { en: 'Text', cs: 'Text' },
            // Note: for basic modules omitting the description: { en: '', cs: '' },

            categories: ['Basic', 'Productivity'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/icons/text.svg',

            author: Authors.rosecky,
        },
        toolbar: ToolbarName.Tools,
        async icon(systems) {
            const { attributesSystem } = await systems.request('attributesSystem');
            return {
                //icon: ({ attributesSystem }) => ({
                name: 'text',
                autoSelect: true,
                order: 40,
                icon: 'text',
                boardCursor: 'text',
                menu: (
                    <>
                        {(await attributesSystem).inputRender('fontStyle')}
                        <Separator />
                        {(await attributesSystem).inputRender('fontSize')}
                        <Separator />
                        {(await attributesSystem).inputRender('color')}
                    </>
                ),
            };
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, appState, attributesSystem, materialArtVersioningSystem } =
                    await systems.request(
                        'touchController',
                        'collSpace',
                        'appState',
                        'attributesSystem',
                        'materialArtVersioningSystem',
                    );

                //appState.cancelSelection();

                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe(async (touch) => {
                        const pickedTextArts = await materialArtVersioningSystem.artsPlaced.filterAsync(async (art) => {
                            return (
                                art instanceof TextArt &&
                                art.isNear(collSpace.pickPoint(touch.firstFrame.position).point)
                            );
                        });

                        if (pickedTextArts.length > 0) {
                            // Note: Select the picked art which is on top
                            // TODO: Is picked exactly text on top?

                            setTemporaryFocus(touch.firstFrame.position);
                            appState.setSelection({
                                selection: null,
                                selected: [pickedTextArts[pickedTextArts.length - 1]],
                            });
                            // TODO: [🍣] Isolate the problems bellow problem together with playground and copilotMonkey into some playground/showcase repository
                            //       [🍣] Focus on part of the text which user clicked on
                            //       [🍣] Drag from part of the text which user clicked on
                            return;
                        }

                        // Note: Creating new text art

                        const artInProcess = new TextArt(
                            '',
                            attributesSystem.getAttributeValue('color').value as string_color,
                            attributesSystem.getAttributeValue('fontSize').value as number,
                            (attributesSystem.getAttributeValue('fontStyle').value as IFontStyleAttributeValue).bold,
                            (attributesSystem.getAttributeValue('fontStyle').value as IFontStyleAttributeValue).italic,
                            (
                                attributesSystem.getAttributeValue('fontStyle').value as IFontStyleAttributeValue
                            ).underline,
                            /*(attributesSystem.getAttributeValue('listStyle') as string) as listStyle*/ 'none',
                        ).setShift(collSpace.pickPoint(touch.firstFrame.position).point);

                        createTextOperation(materialArtVersioningSystem, artInProcess);

                        setTemporaryFocus(touch.firstFrame.position);
                        appState.setSelection({ selected: [artInProcess] });
                    }),
                );
            },
        },
    }),
);

/**
 * For iOs devices which does not trigger the keyboard
 * TODO: Better and extract + connect with [🧶]
 *
 * @see https://stackoverflow.com/questions/54424729/ios-show-keyboard-on-input-focus
 */
function setTemporaryFocus(position: IVector) {
    const temporaryFocusInput = document.createElement('input');
    temporaryFocusInput.style.position = 'absolute';
    temporaryFocusInput.style.left = (position.x || 0) + 'px';
    temporaryFocusInput.style.top = (position.y || 0) + 'px';
    temporaryFocusInput.style.height = '0';
    temporaryFocusInput.style.opacity = '0';

    document.body.appendChild(temporaryFocusInput);
    temporaryFocusInput.focus();

    // Note: This is not returned as promise, because it is irrelevant to outside
    setTimeout(() => {
        document.body.removeChild(temporaryFocusInput);
    }, 1000);
}

/**
 * TODO: [🍣] DO not persist (delete) until first char is written
 *       [🍣] Remove the art if last char is deleted and unselected
 * TODO: [👀] Focus cursor as a thing which better keeps focus
 * TODO: [👀] Focus cursor from other users in edited texts
 * TODO: [🍩][🧠] Requesting systems non-magically
 * TODO: [🍩] Omitting systems.request should fail bacause of unrequested systems.
 */
