import { Registration } from 'destroyable';
import { Transform } from 'xyzt';
import { IAppearance } from '../../CollSpace/appearance/IAppearance';
import { IShape } from '../../CollSpace/IShape';
import { ToolbarName } from '../../ToolbarSystem/0-ToolbarSystem';
import { IModuleDefinition } from '../interfaces/IModule';
import { IMakeFunctionalArtModuleOptions, makeFunctionalArtModule } from './art/30-makeFunctionalArtModule';
import { makeIconModuleOnModule } from './makeIconModuleOnModule';

interface ISimpleArtoatModulesOptions<
    TShape extends IShape = IShape,
    TAppearance extends IAppearance = IAppearance,
    // <- TODO: !!x Should be this generic under makeFunctionalArtModule+IMakeFunctionalArtModuleOptions OR just createArt
> extends IMakeFunctionalArtModuleOptions<TShape, TAppearance> {
    /*
    TODO: !!x Just render default without using icon
    TODO: !!x makeSimpleIcon
    icon:
        | IToolbarIcon
        | ((systems: ISystems) => IToolbarIcon) /* <- TODO: Some way how to pass arguments into IFactorable * /;
    */
}

/**
 * @@x
 *
 * @param options @@x
 * @returns @@x
 *
 * @collboard-modules-sdk
 */
export function makeSimpleArtoolModules<
    TShape extends IShape = IShape,
    TAppearance extends IAppearance = IAppearance,
    // <- TODO: !!x Should be this generic under makeFunctionalArtModule+IMakeFunctionalArtModuleOptions OR just createArt
>(options: ISimpleArtoatModulesOptions<TShape, TAppearance>): [IModuleDefinition, IModuleDefinition] {
    const { manifest /* TODO: !!x icon,*/, createArt } = options;

    const artModule = makeFunctionalArtModule({
        manifest: {
            ...manifest,
            name: `${manifest.name}/art`,
        },
        createArt,
    });

    const toolModule = makeIconModuleOnModule({
        manifest: {
            ...manifest,
            name: `${manifest.name}/tool`,
        },
        toolbar: ToolbarName.Tools,
        async icon(systems) {
            // const { attributesSystem } = await systems.request('attributesSystem');
            return {
                //icon: ({ attributesSystem }) => ({

                autoSelect: true,
                order: 1000 /* <- TODO: What should be default - make some dictionary with order/priority */,

                /**
                 * Note: @@x
                 */
                icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/cursors/circle-blue.svg' /* <- TODO: !!x Dynamically from art, but in some other util like makeSimpleIcon + use all utils together in new maker makeCanvasArtoatModules */,

                /**
                 * Note: @@x
                 */
                boardCursor: `url("https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/cursors/circle-blue.svg") 12.5 12.5, crosshair` /* <- !!x Dynamically from art, same as "icon" */,

                /*
                TODO: [🏹][👀] Show the cursor ring on mobile
                TODO: [🏹][👀] Change according to color and weight changes
                TODO: [🏹] Make boardCursor Arrayable (OR some more complex object representing a cursor):
                    > [
                    >     `url("https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/cursors/circle-blue.svg")`,
                    >     `url("https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/cursors/circle-blue.cur")`,
                    >     'crosshair',
                    > ]
                */

                /*
                TODO: [0]
                menu: (
                    <>
                        {attributesSystem.inputRender('weight')}
                        <Separator />
                        {attributesSystem.inputRender('color')}
                    </>
                ),
                */
            };
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, collSpace, appState, materialArtVersioningSystem } = await systems.request(
                    'touchController',
                    'collSpace',
                    'appState',
                    'materialArtVersioningSystem',
                );

                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe((touch) => {
                        appState.cancelSelection();

                        // TODO: [👘] Nicer syntax
                        const frame = touch.firstFrame.clone(/* TODO: [👘] !!x Do wee need to clone the frame? */);
                        frame.position = collSpace.pickPoint(frame.position).point;

                        const art = artModule.createArt({
                            // TODO: !!x Allow to choose
                            shape: {
                                /*
                                    TODO: !!x [2]
                                    spotSize:
                                        (attributesSystem.getAttributeValue('weight').value as number) * 3 +
                                        5 /* <- DRY [🌞] * /,
                                    */
                            } as any,
                            appearance: {
                                color: '#000000' /* <- TODO: !!x Allow empty appearence */,
                                //TODO: !!x [2]> color: attributesSystem.getAttributeValue('color').value as string_color,
                            } as any,
                            transform: Transform.translate(frame.position),
                        });

                        materialArtVersioningSystem
                            .createPrimaryOperation()
                            .newArts(art /* <- TODO: !!x! What about serialization */)
                            .persist();
                    }),
                );
            },
        },
    });

    return [artModule, toolModule];
}

/**
 * TODO: !!x Should be in the maker name attribute? If no rename also in 0-samples
 * TODO: !!x Some makers does not return ModuleDefinition but Art or multiple modules, (and things like proposed makeSimpleIcon), should they be called makers
 * TODO: !!x! pass here art module and not JSX component (and if you want to pass JSX, use two makers)
 * TODO: !!x Extract from here makeArtModule
 * TODO: !!x [0] Do we need attribute
 * TODO: Make this helper function for just a canvas (and babylon)
 * TODO: !!x Better name than options
 * TODO: !!x Better name
 * TODO: !!x Use or not to use simple prefix in maker
 */
