import React from 'react';
import { Authors } from '../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../50-systems/ModuleStore/internalModules';
import { makeAttributeModule } from '../../50-systems/ModuleStore/makers/makeAttributeModule';

/**
 * Note: Color2AttributeModule, Color3AttributeModule and Weight2AttributeModule are here just to demonstrate how non standard attribute modules works.
 */

internalModules.declareModule(() =>
    makeAttributeModule({
        manifest: {
            // Note: This art module is not auto-activated on initialization because when it appears on the board or in some toolbar, it will be auto-activated afterward.
            name: 'Weight2Attribute',
            title: { en: 'Smooth weight', cs: 'Plynulá tloušťka' },
            description: {
                en: 'Allows user to pick more precisely weight of line he wants.',
                cs: 'Umožňuje uživateli výběr z více různých sil pera.',
            },

            categories: ['Experimental' /* TODO: Probbably experimental should be flag or some dev stage */, 'Art'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/icons/stroke-3.svg',

            contributors: [Authors.hejny],
        },
        standard: false,
        attribute: {
            type: 'number',
            name: 'weight',
            defaultValue: 2,
            // TODO: min, max
        },
        inputRender(value: number, onChange: (value: number) => void) {
            return (
                <>
                    {/* TODO: Cannot change values after first change */}
                    <div className="input-slider input-slider-width">
                        <input
                            type="range"
                            className="slider"
                            min={1}
                            max={20}
                            step={1}
                            defaultValue={value}
                            onChange={(event) => onChange(Number.parseInt(event.target.value, 10))}
                        />
                    </div>
                </>
            );
        },
    }),
);
