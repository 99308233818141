import React from 'react';
import styled from 'styled-components';
import { Authors } from '../../../50-systems/ModuleStore/Authors';
import { internalModules } from '../../../50-systems/ModuleStore/internalModules';
import { makeUserInterfaceModule } from '../../../50-systems/ModuleStore/makers/makeUserInterfaceModule';
import { UserInterfaceElementPlace } from '../../../50-systems/UserInterfaceSystem/UserInterfaceElementPlace';

internalModules.declareModule(() =>
    makeUserInterfaceModule({
        manifest: {
            name: '@collboard/internal/collboard-logo',
            deprecatedNames: ['@collboard/collboard-logo', 'CollboardLogo'],
            title: { en: 'Collboard logo', cs: 'Collboard logo' },
            flags: {
                isHidden: true,
            },
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/logo-small.png',
            author: Authors.collboard,
            requirePermissions: [],
        },

        place: UserInterfaceElementPlace.EdgeTop,
        order: -10,

        async render(systems) {
            const { boardSystem } = await systems.request('boardSystem');

            return (
                <LogoStyled href="/">
                    <img
                        src={`https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/logo-small.png`}
                        alt="Logo icon Collboard.com"
                        onClick={(event) => {
                            event.preventDefault();
                            /* not await */ boardSystem.navigateBoard(null);
                        }}
                    />

                    {/*
                    <img
                        src={`https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/logo/handwritten-collboard-logo.svg`}
                        alt="Logo icon Collboard.com"
                    />
                     */}

                    {/*
                    [🌘]
                    <img src={`https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/logo/logo-squircle.svg`} alt="Logo icon Collboard.com" />
                    */}

                    {/*<img src={`https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/logo/logo (1).svg`} alt="Logo icon Collboard.com" />*/}

                    {/* [0]

                    <img src={`https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/logo/w1.svg`} alt="Logo icon Collboard.com" />
                    <img src={`https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/logo/w2.svg`} alt="Logo icon Collboard.com" />
                    <img src={`https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/logo/square.svg`} alt="Logo icon Collboard.com" />
                    */}
                </LogoStyled>
            );
        },
    }),
);

const LogoStyled = styled.a`
    display: inline-block;
    margin-right: 0 !important;
    position: relative;

    img {
        height: 35px;
        margin-top: 3px;
    }

    @media /*[🍕]*/ only screen and (max-width: 575px) {
        img {
            height: 20px;
        }
    }
`;

/**
 * TODO: [🌘] Wrap the logo around simmilar component as <HandwrittenCollboardLogo/>
 * TODO: [🌘] Find here the best logo
 * TODO: [🌘] Take all external css and put it here (.brand) + doublecheck export
 * TODO: [LogoDesign][0]! Make new logo look good
 * TODO: Pack logo image with this module
 * TODO: Indipendent style with style system
 * [🏯]
 */
