import { Color } from '../../../40-utils/color/Color';
import { makeSkinModule } from '../../../50-systems/StyleSystem/makers/makeSkinModule';

export const DarkDevelopmentSkinModule = makeSkinModule({
    manifest: { name: 'DarkDevelopmentSkin' },
    skin: {
        // TODO: Some smart way how to inherit style from DefaultEducationSkin

        welcomeWallpaper: {
            // TODO: Put this 👇 file into the assets folder of the module
            backgroundImage: `url('https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/wallpapers/grid-dark-wallpaper.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
        },

        borderRadius: 6,
        colors: {
            primary: Color.fromHex('#009edd'),
            light: Color.fromHex('#f2f2f2').negative,
            dark: Color.fromHex('#4e4e4e').negative,

            success: Color.fromHex('#3da948'),
            warning: Color.fromHex('#ffcc11'),
            error: Color.fromHex('#cd1719'),
            danger: Color.fromHex('#cd1719'),

            white: Color.fromString('white').negative,
            black: Color.fromString('black').negative,

            overlay: Color.fromHex('#009edd').withAlpha(0.2),
        },
        darken: -0.05,
        lighten: 0.05,
        circles: true,
    },
});
