import { Registration } from 'destroyable';
import { Transform, Vector } from 'xyzt';
import { Authors } from '../50-systems/ModuleStore/Authors';
import { internalModules } from '../50-systems/ModuleStore/internalModules';
import { makeIconModuleOnModule } from '../50-systems/ModuleStore/makers/makeIconModuleOnModule';
import { ToolbarName } from '../50-systems/ToolbarSystem/0-ToolbarSystem';
internalModules.declareModule(() =>
    makeIconModuleOnModule({
        manifest: {
            name: '@collboard/internal/move-tool',
            deprecatedNames: ['@collboard/move-tool', 'MoveTool'],
            title: { en: 'Move board', cs: 'Pohyb po tabuli' },
            // Note: for basic modules omitting the description: { en: '', cs: '' },

            categories: ['Basic', 'Productivity'],
            icon: 'https://collboard.fra1.cdn.digitaloceanspaces.com/assets/35.13.22/icons/hand.svg',
            author: Authors.collboard,
            requirePermissions: ['view'],
        },
        toolbar: ToolbarName.Navigation,
        icon: {
            name: 'move',
            order: 100,
            section: 1,
            icon: 'hand',
            boardCursor: 'grab',
        },
        moduleActivatedByIcon: {
            async setup(systems) {
                const { touchController, appState } = await systems.request('touchController', 'appState');

                return Registration.fromSubscription((registerAdditionalSubscription) =>
                    touchController.touches.subscribe((touch) => {
                        registerAdditionalSubscription(
                            touch.frameTuples({ itemsPerTuple: 2 }).subscribe(([frame1, frame2]) => {
                                appState.transform.next(
                                    appState.transform.value.apply(
                                        Transform.translate(Vector.subtract(frame2.position, frame1.position)),
                                    ),
                                );
                            }),
                        );
                    }),
                );
            },
        },
    }),
);
